import _ from "lodash";
import React, { useState, useEffect } from "react";

// import Swiper JS
import Swiper from "swiper";
import { Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/swiper-bundle.css";

// import "swiper/css";

export const SwiperBlock = (props) => {
  //
  const { setCurrentSlideHandler = new Function(), withEffects } = props;
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [swiper, setSwiper] = useState(null);
  // const swiper = useRef();
  const modules = [Pagination];

  if (withEffects) {
    modules.push(EffectCoverflow);
  }
  useEffect(() => {
    const slidesPerView = +props.slidesPerView || 1;
    setSlidesPerView(slidesPerView);
    const options = {};
    const swiper = new Swiper(`#${props.id}`, {
      speed: 400,
      spaceBetween: 15,

      // mousewheel: true,
      createElements: true,
      loop: slidesPerView === 1,
      centeredSlides: slidesPerView === 1,
      slidesPerView: slidesPerView + (slidesPerView === 1 ? 0.2 : 0.4),
      grabCursor: true,
      // slidesPerView,
      pagination: { el: ".swiper-pager" },
      on: {
        slideChange: (swiper) => {
          setCurrentSlideHandler(swiper.activeIndex);
          // setCurrentSlide(swiper.activeIndex);
          // swiper.changeDirection();
          // swiper.update();
        },
      },

      ...(withEffects
        ? {
            centeredSlides: true,

            effect: "coverflow",
            coverflowEffect: {
              depth: 500,
              rotate: 0,
            },
            enabled: false,
            initialSlide: _.size(props.slides) > 2 ? 1 : 0,
            slidesPerView: 3 - 0.6 //slidesPerView - (slidesPerView === 1 ? 0.2 : 0.6),
            // autoplay: { delay: 3000 },
            // loop: _.size(props.slides) > 1,
          }
        : {}),
      modules,
    });
    setSwiper(swiper);
    if (slidesPerView === 1) {
      swiper.slideToLoop();
    }
    // swiper.updateSlides();
    // Now you can use all slider methods like
    // swiper.slideNext();
  }, [props]);
  // console.log(Swiper);
  return (
    <div className="swiper-body">
      <div className="swiper" id={props.id}>
        <div className="swiper-wrapper">
          {_.map(props.slides, (slide, index) => {
            return (
              <div className="swiper-slide" key={index}>
                {slide}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

// export default connect(null, {})(SwiperBlock);
