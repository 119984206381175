import _ from "lodash";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import qs from "query-string";
import "moment/locale/ru";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { iconFontSize } from "constants/index";

// import SearchCastsChannels from "components/standart/searchCastsChannels";

import { fileUrl } from "reducers/api";
import SoundMessagePlayButton from "components/standart/soundMessagePlayButton";
import CastsFavoriteButton from "components/standart/cast-favorite-button";

import {
  SmartImage,
  LoadingIcon,
  scrollTop,
  castTitle,
  castFirstSound,
  keywordsSlice,
} from "components/standart";
import { CastChannelsLogos } from "components/standart/casts";

import { ShareCastButton } from "components/standart/share-button";
import nl2br from "react-nl2br";
import {
  setPlayerMessages,
  setPlayerVisible,
  setPlayerSound,
  setPlayerCurrentId,
  setPlayerPlay,
  setPlayerCastId,
  setPlayerFavoritePlaying,
  setPlayerChannels,
} from "actions";

import CastsRecomendation from "../casts/recomendation";
import CastsSearchModal from "../casts/search";

import CastMessages from "./messages";
import SpecialsRecomendation from "./recomendation";
import { InfoBlocks, SubscribeBlock } from "../infoblocks";

import { TranslationsLinkBlock } from "../live";
const style = { fontSize: iconFontSize };

const CastData = (props) => {
  const { cast } = props;
  const translate = useTranslate();

  const playlist = useSelector((state) => state.playlist);

  useEffect(() => {
    scrollTop("cast.jsx");
  }, []);

  const castDescription = (cast) => {
    return cast.text;
  };

  const castImage = (cast) => {
    return fileUrl(cast.fileId, "image.jpg");
  };

  return _.size(cast) ? (
    <>
      <Helmet>
        <title>Radiogram | {castTitle(cast)}</title>
        <meta name="description" content={castDescription(cast)} />
        <meta property="og:title" content={castTitle(cast)} />
        <meta name="og:description" content={castDescription(cast)} />
        <meta property="og:image" content={castImage(cast)} />
        <meta property="image" content={castImage(cast)} />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="text-center pt-2">
        <div className="d-flex justify-content-center">
          <div style={{ width: "100%" }}>
            <CastInfo
              {...props}
              // allKeywords={true}
            >
              {playlist.currentCastId === cast._id ? (
                <div className="py-4">
                  <div className="d-flex justify-content-center">
                    <ShareCastButton special={cast} />
                    <div className="mx-4" />
                    <PlayerButton {...cast} />
                    <div className="mx-4" />
                    <CircleFavoriteButton {...cast} />
                  </div>
                </div>
              ) : (
                <div className="p-5 text-center">
                  <LoadingIcon />
                </div>
              )}
            </CastInfo>
          </div>
        </div>
        {/* <SubscribeBlock {...cast} /> */}
        <div>
          <CastMessages {...props} />
        </div>

        {/* 
        <div className="pb-4">
          <Link
            to={cast.channelId ? `/${cast.channel.alias}` : `/${cast.category.alias}-top`}
            className="btn btn-solid white-btn standart-header"
            style={{ border: "2px solid black" }}
          >
            <i className="far fa-archive mr-2" />
            {translate("casts_archive")}
          </Link>
        </div>
        */}
        <div className="pt-4">
          <div className="pb-3">
            <SpecialsRecomendation
              title={translate("casts_archive")}
              // firstBlocks={[<TranslationsLinkBlock />]}
              query={{ categoryId: cast.categoryId }}
              skipId={cast._id}
              showMore={{ alias: `specials?categoryId=${cast.categoryId}` }}
            />
            <div className="py-1"></div>
          </div>
        </div>

        <CastsRecomendation
          title={translate("now_listening")}
          firstBlocks={[<TranslationsLinkBlock />]}
          query={{ channelsOnly: true, lastOnly: true }}
          skipId={cast._id}
          showMore={{ alias: "" }}
        />

        <InfoBlocks showLiveStream={false} />
      </div>
    </>
  ) : (
    <div className="p-5 text-center">
      <LoadingIcon />
    </div>
  );
};

export const CastInfo = (props) => {
  const { cast, defaultImageId, allKeywords } = props;
  const translate = useTranslate();
  const navigate = useNavigate();

  const [searchModal, setSearchModal] = useState(null);
  const openSearchModal = () => {
    setSearchModal(true);
  };
  const closeSearchModal = () => setSearchModal(null);

  const modalRef = useRef(null);

  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;

  const channelUrl = settings.telegramChannel;
  const channelName = `${channelUrl}`.substring("https://".length);

  return (
    <div className="text-center">
      <div className="row">
        <div className="col-2"></div>
        <div className="col">
          <div className="position-relative">
            {cast.fileId ? (
              <div className="text-center position-relative rounded rounded-xl  border border-1">
                <div
                  className="block-squared "
                  // style={backgroundCover(fileUrl(cast.fileId || defaultImageId, "image.jpg"))}
                ></div>
                <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
                  <SmartImage
                    src={fileUrl(cast.fileId, "image.jpg")}
                    style={{ objectFit: "cover", overflow: "hidden" }}
                    className="rounded rounded-xl w-100"
                  />
                </div>
              </div>
            ) : null}

            <div className="position-absolute w-100 cover-text-block d-flex rounded rounded-xl">
              <div className="mt-auto w-100">
                <CastChannelsLogos {...cast} size="micro" limit={5} />
                <div className="pb-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="-position-absolute w-100 -cover-text-block d-flex rounded rounded-xl">
        <div className="mt-auto w-100">
          {/* 
          {showFavorite ? <FavoriteButtonBlock {...cast} /> : null}
           */}
          <div className="pt-3" />
          <div className="large-header -font-weight-bold mb-1 mx-3 -text-truncate">
            <div>{castTitle(cast)}</div>
          </div>

          <div className="pb-2 d-flex flex-wrap align-content-center justify-content-center">
            <GreyButton
              // text={cast.category?.name}
              text={translate("specials_casts")}
              style={{ cursor: "pointer" }}
              onClick={() => openSearchModal()}
            />

            <GreyButton
              style={{ cursor: "pointer" }}
              text={translate("ai_reading")}
              onClick={() => navigate("/about")}
            />
          </div>

          <div className="pb-2 text-secondary">
            <small>
              <ShowDifference {...cast} />
              <span className="mx-2" />
              <ShowCastDuration {...cast} />
            </small>
          </div>

          <div className={`px-2 text-center mb-1`}>{cast.text}</div>

          <div className="mt-2">
            <a href={channelUrl} style={{ textDecoration: "none", color: "inherit" }}>
              <small className="text-secondary">
                <i className="fab fa-telegram-plane mr-2" />
                {channelName}
              </small>
            </a>
          </div>

          <div>{props.children}</div>
          {/* <div className="pt-4 pb-4">{props.children}</div> */}
        </div>
      </div>
      {/* {cast.introFileId && <SoundMessagePlayButton sound={castSoundMessage} />} */}

      {/* <button className="btn btn-solid" onClick={playCastTrailer}>
        трейлер
      </button> */}

      {searchModal ? (
        <Modal
          isOpen={true}
          centered
          toggle={closeSearchModal}
          wrapClassName={"fullscreen-modal"}
          innerRef={modalRef}
        >
          <ModalHeader toggle={closeSearchModal}></ModalHeader>
          <ModalBody>
            {/* <SearchCastsChannels modalRef={modalRef} toggleModal={toggleModal} /> */}
            <CastsSearchModal
              modalRef={modalRef}
              closeHandler={closeSearchModal}
              categoryId={searchModal}
            />
          </ModalBody>
        </Modal>
      ) : null}
    </div>
  );
};

export const CircleFavoriteButton = (cast) => {
  const categoryId = cast.categoryId;

  const translate = useTranslate();
  return (
    <div className="d-flex flex-column">
      <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
        <div className=" w-100 h-100">
          <img src={"/grey.png"} className="rounded-circle -border -border-1" />
        </div>

        <div
          className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
          style={{ ...style }}
        >
          <CastsFavoriteButton specialCategoryId={categoryId} style={{ padding: "0 6px" }} />
        </div>
      </div>
      <div className="text-secondary mt-1">
        <small>{translate("favorites_simple")}</small>
      </div>
    </div>
  );
};

export const GreyButton = (props) => {
  return (
    <div className="m-1" onClick={props.onClick} style={{ ...props.style }}>
      <small className="text-nowrap py-1 px-2 rounded bg-grey ">{props.text}</small>
    </div>
  );
};

export const CastDownText = (cast) => {
  const translate = useTranslate();
  const { env } = useSelector((state) => state.frontend);
  const { settings = {} } = env;

  return cast.runtime?.periodTitle ? (
    <>
      {cast.runtime?.periodTitle}
      {cast.channelId ? (
        <>
          {" "}
          <a href={`https://t.me/${cast.channel.alias}`} className="text-secondary">
            {cast.channel.name}
          </a>{" "}
          {/* &times; */}
        </>
      ) : null}
      {/* 
      {" "}
      <a href={`${settings.telegramChannel}`} className="text-secondary">{`Radiogram`}</a>
       */}
    </>
  ) : null;
};

export const CastInfoSmall = (props) => {
  const { cast } = props;
  return (
    <div className="text-center">
      <div className="position-relative ">
        {cast.fileId ? (
          <div className="text-center position-relative  rounded rounded-xl border border-1">
            <div
              className="block-squared -d-md-none"
              // style={backgroundCover(fileUrl(cast.fileId || defaultImageId, "image.jpg"))}
            ></div>
            <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
              <SmartImage
                src={fileUrl(cast.fileId, "image.jpg")}
                style={{ objectFit: "cover", overflow: "hidden" }}
                className="rounded rounded-xl w-100"
              />
            </div>
          </div>
        ) : null}

        <div className="position-absolute w-100 cover-text-block-small d-flex rounded rounded-xl">
          <div className="mt-auto w-100">
            <CastChannelsLogos {...cast} limit={5} />
            <div className="pb-2" />
          </div>
        </div>
      </div>
      <div className="-position-absolute w-100 -cover-text-block d-flex -text-white rounded rounded-xl">
        {/* 
          {showFavorite ? <FavoriteButtonBlock {...cast} /> : null}
           */}
        <div className="mt-auto w-100 text-center">
          <div className="standart-header font-weight-bold mt-1 mb-1 -mx-3 -text-truncate player-text-2">
            <div>{castTitle(cast)}</div>
          </div>
          {/* 
            <div className="px-2 mb-2">
              {cast.keywords.slice(0, 3).join(". ")}
              {"..."}
            </div>
            */}
          <div className="pb-1 text-secondary">
            <small>
              <ShowDifference {...cast} />
              <span className="mx-2" />
              <ShowCastDuration {...cast} />
            </small>
          </div>

          {props.showKeywords ? <div className="py-1">{keywordsSlice(cast.keywords)}</div> : null}
          {/* <div>{props.children}</div> */}
          {/* <div className="pt-4 pb-4">{props.children}</div> */}
        </div>
      </div>
    </div>
  );
};

const calcDuration = (totalSeconds) => {
  const secondsInMinute = 60;
  const secondsInHour = secondsInMinute * 60;
  const hours = 0 * Math.floor(totalSeconds / secondsInHour);
  const minutes = Math.ceil((totalSeconds - hours * secondsInHour) / secondsInMinute);
  const seconds = 0 * Math.floor(totalSeconds - hours * secondsInHour - minutes * secondsInMinute);
  return { hours, minutes, seconds };
};

export const ButtonCastDuration = (cast) => {
  const duration = calcDuration(cast.duration);

  const translate = useTranslate();
  const result = [
    duration.hours ? translate("hours", { value: duration.hours }) : null,
    duration.minutes ? translate("minutes", { value: duration.minutes }) : null,
    duration.seconds ? translate("seconds", { value: duration.seconds }) : null,
  ].filter(Boolean);
  return (
    <button className="btn btn-outline py-3 standart-header text-nowrap">
      <i className={`fas fa-play mr-1`} />
      {result.join(" ")}
    </button>
  );
};

export const ShowCastDuration = (cast) => {
  const duration = calcDuration(cast.duration);

  const translate = useTranslate();
  const result = [
    duration.hours ? translate("hours", { value: duration.hours }) : null,
    duration.minutes ? translate("minutes", { value: duration.minutes }) : null,
    duration.seconds ? translate("seconds", { value: duration.seconds }) : null,
  ].filter(Boolean);
  return (
    <span>
      <i className={`fal fa-headphones mr-1`} />
      {result.join(" ")}
    </span>
  );
};

const PlayerButton = (cast) => {
  const dispatch = useDispatch();
  const player = useSelector((state) => state.player);
  const translate = useTranslate();
  const playlist = useSelector((state) => state.playlist);
  const [isPlayed, setPlayed] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const duration = calcDuration(cast.duration);

  const result = [
    duration.hours ? translate("hours", { value: duration.hours }) : null,
    duration.minutes ? translate("minutes", { value: duration.minutes }) : null,
    duration.seconds ? translate("seconds", { value: duration.seconds }) : null,
  ].filter(Boolean);

  useEffect(() => {
    setPlayed(player.play && player.currentCastId === cast._id);
  }, [player.play, player.currentCastId]);

  const commonPlayHandler = useCallback(() => {
    if (player.disabled) {
      return null;
      // dispatch(setPlayerDisabled(false));
    }
    if (isPlayed) {
      dispatch(setPlayerPlay(false));
    } else {
      if (player.currentCastId === cast._id) {
        dispatch(setPlayerPlay(true));
      } else {
        const firstSound = castFirstSound(cast, playlist.messages);
        const { favorites } = qs.parse(location.search);
        dispatch(setPlayerFavoritePlaying(!!favorites));
        dispatch(setPlayerCastId(playlist.currentCastId));
        dispatch(setPlayerChannels(playlist.channels));
        dispatch(setPlayerMessages(playlist.messages));
        dispatch(setPlayerSound(firstSound));
        dispatch(setPlayerCurrentId(null));
        setTimeout(() => {
          dispatch(setPlayerVisible(true));
          dispatch(setPlayerPlay(true));
        }, 50);
      }
    }
  }, [player, cast, playlist]);

  // return (
  //   <button className="btn btn-solid standart-header" onClick={commonPlayHandler}>
  //     <i className={`fas ${isPlayed ? "fa-pause" : "fa-play"} text-white mr-2`} />
  //     Слушать
  //   </button>
  // );
  return (
    <div className="d-flex flex-column">
      <div
        className="position-relative d-flex"
        onClick={commonPlayHandler}
        style={{ cursor: "pointer" }}
      >
        <div className=" w-100 h-100">
          <img
            src={"/black.png"}
            className="rounded-circle"
            // style={{ border: "2px solid black" }}
          />
        </div>
        <div
          className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
          style={{ ...style }}
        >
          <i className={`fas ${isPlayed ? "fa-pause" : "fa-play"} text-white`} />
        </div>
      </div>
      <div className="text-secondary mt-1">
        <small>{translate("playlist_play")}</small>
      </div>
    </div>
  );
};

const TrailerButton = (props) => {
  const { cast } = props;
  const translate = useTranslate();
  const castSoundMessage = {
    button: translate("cast_trailer"),
    title: cast.name,
    fileId: cast.soundTextFileId,
    content: cast.text,
    imageId: cast.fileId,
  };

  return cast.soundTextFileId ? (
    <SoundMessagePlayButton
      sound={castSoundMessage}
      component={
        <div className="d-flex flex-column">
          <div className="position-relative d-flex" style={{ cursor: "pointer" }}>
            <div className=" w-100 h-100">
              <img
                src={"/grey.png"}
                className="rounded-circle"
                // style={{ border: "2px solid black" }}
              />
            </div>
            <div
              className="position-absolute d-flex align-items-center justify-content-center h-100 w-100"
              style={{ ...style }}
            >
              <i className="far fa-file-audio" />
            </div>
          </div>
          <div className="text-secondary mt-1">
            <small>{translate("cast_trailer")}</small>
          </div>
        </div>
      }
    />
  ) : null;
};

const ShowCastTitle = (cast) => {
  return cast.title ? (
    <>
      <i className="far fa-sync-alt mr-1" />
      {cast.title}
    </>
  ) : null;
};

const ShowDifference = (currentCast) => {
  const calcDifference = (publishedDate) => {
    const now = moment(new Date());
    const end = moment(publishedDate).startOf("day");
    const diff = moment.duration(now.diff(end));
    const days = Math.floor(diff.asDays());
    const hours = Math.floor(diff.asHours());
    const minutes = Math.floor(diff.asMinutes());
    return { days, hours, minutes };
  };

  moment.locale("ru");
  const difference = calcDifference(currentCast.publishedAt);

  const translate = useTranslate();
  const result = [
    difference.days ? translate("days", { value: difference.days }) : null,
    !difference.days && difference.hours ? translate("hours", { value: difference.hours }) : null,
    !difference.days && !difference.hours && difference.minutes
      ? translate("minutes", { value: difference.minutes })
      : null,
    difference.minutes ? translate("ago") : translate("right_now"),
  ].filter(Boolean);

  const publishDate = moment(currentCast.publishedAt);
  return (
    <span>
      <i className="fal fa-clock mr-1" />
      {difference.days > 1 ? (
        // moment(currentCast.publishedAt).format("DD MMMM")
        publishDate.format("DD.MM")
      ) : (
        <>
          {difference.days < 1
            ? translate("today") // currentCast.runtime.time
            : translate("yesterday")}

          {/* {difference.days < 1
            ? currentCast.runtime.time
            : difference.days > 1
            ? translate(`day_${publishDate.isoWeekday()}`)
            : translate("yesterday")} */}
        </>
        // <>{difference.days < 1 ? result.join(" ") : translate("yesterday")}</>
      )}
    </span>
  );
};

const AiReading = () => {
  const translate = useTranslate();

  return (
    <span>
      <i className="fal fa-microphone mr-1" />
      {translate("ai_reading")}
    </span>
  );
};

export const MoreCasts = ({ alias, _id }) => {
  const navigate = useNavigate();
  const translate = useTranslate();
  const [searchModal, setSearchModal] = useState(false);
  const modalRef = useRef(null);
  const toggleModal = () => setSearchModal(!searchModal);
  const clickHandler = (event) => {
    // console.log ('do click more casts');
    event.preventDefault();
    event.stopPropagation();
    if (_id) {
      toggleModal();
    } else {
      navigate(`/${alias}`);
    }
  };

  return (
    <div onClick={clickHandler}>
      <div className="py-2 mb-2" style={{ cursor: "pointer", marginTop: "0px", zIndex: 9999 }}>
        <small className="text-secondary">
          {translate("show_more")}
          <i className="fa fa-angle-right ml-2" />
        </small>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(CastData);
