// whatsapp message
export const SET_WHATSAPP_MESSAGE = "SET_WHATSAPP_MESSAGE";

// navigator
export const SET_NAVI = "SET_NAVI";
export const RESET_NAVI = "RESET_NAVI";
export const SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT";
export const SET_BOTTOM_HEIGHT = "SET_BOTTOM_HEIGHT";
export const SET_SHOPFILTER_OPEN = "SET_SHOPFILTER_OPEN";
export const SET_SHOPFILTER_OPTIONS = "SET_SHOPFILTER_OPTIONS";
export const SET_LOCALE_COUNTRY = "SET_LOCALE_COUNTRY";
export const SET_LOCALE_DATA = "SET_LOCALE_DATA";

export const RESET_PLAYER = "RESET_PLAYER";
export const SET_PLAYER_LIST = "SET_PLAYER_LIST";
export const SET_PLAYER_CHANNELS = "SET_PLAYER_CHANNELS";
export const ADD_PLAYER_LIST = "ADD_PLAYER_LIST";
export const SET_PLAYER_MESSAGE = "SET_PLAYER_MESSAGE";
export const SET_PLAYER_CAST_ID = "SET_PLAYER_CAST_ID";
export const SET_PLAYER_PLAYING_ID = "SET_PLAYER_PLAYING_ID";
export const SET_PLAYER_ADVERT = "SET_PLAYER_ADVERT";
export const SET_PLAYER_LAST_CHANNEL = "SET_PLAYER_LAST_CHANNEL";
export const SET_PLAYER_SOUND = "SET_PLAYER_SOUND";
export const SET_PLAYER_DISABLED = "SET_PLAYER_DISABLED";
export const SET_PLAYER_DATA = "SET_PLAYER_DATA";
export const SET_PLAYER_VISIBLE = "SET_PLAYER_VISIBLE";
export const SET_PLAYER_FAVORITE_PLAYING = "SET_PLAYER_FAVORITE_PLAYING";
export const TOGGLE_PLAYER_PLAY = "TOGGLE_PLAYER_PLAY";
export const SET_PLAYER_PLAY = "SET_PLAYER_PLAY";
export const SET_PLAYER_JS = "SET_PLAYER_JS";
export const SET_PLAYER_CURRENT_ID = "SET_PLAYER_CURRENT_ID";
export const SET_PLAYER_PROGRESS = "SET_PLAYER_PROGRESS";
export const SET_SHOW_CHANNEL = "SET_SHOW_CHANNEL";
export const SET_PLAYER_INCREASE_SPEED = "SET_PLAYER_INCREASE_SPEED";
export const SAFE_ADD_PLAYER_MESSAGE = "SAFE_ADD_PLAYER_MESSAGE";
export const SAFE_DELETE_PLAYER_MESSAGE = "SAFE_DELETE_PLAYER_MESSAGE";

export const RESET_PLAYLIST = "RESET_PLAYLIST";
export const SET_PLAYLIST_CHANNELS = "SET_PLAYLIST_CHANNELS";
export const ADD_PLAYLIST_MESSAGE = "ADD_PLAYLIST_MESSAGE";
export const SET_PLAYLIST_MESSAGES = "SET_PLAYLIST_MESSAGES";
export const SET_PLAYLIST_CAST_ID = "SET_PLAYLIST_CAST_ID";
export const SET_PLAYLIST_LISTENED = "SET_PLAYLIST_LISTENED";
export const ADD_PLAYLIST_LISTENED = "ADD_PLAYLIST_LISTENED";
export const SAFE_ADD_PLAYLIST_MESSAGE = "SAFE_ADD_PLAYLIST_MESSAGE";
export const SAFE_DELETE_PLAYLIST_MESSAGE = "SAFE_DELETE_PLAYLIST_MESSAGE";
