import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useTranslate } from "react-redux-multilingual";
import { Link, useNavigate } from "react-router-dom";
import pluralRu from "plural-ru";

import { SwiperBlock } from "components/standart/swiper-block";
import { CastChannelsLogos } from "components/standart/casts";

import { userApi, fileUrl } from "reducers/api";
import { SmartImage, useWindowSize, castTitle } from "components/standart";

import { CastInfoSmall } from "../casts/cast";
import nl2br from "react-nl2br";
import { MoreCasts } from "views/specials/cast";

const { api } = userApi.actions;
const getCasts = api.get("/account/casts-favorites");

const FavoritesCasts = (props) => {
  const { swiper } = props;
  const size = useWindowSize();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [casts, setCasts] = useState([]);
  const [titles, setTitles] = useState([]);

  const extractTitles = (casts) => {
    // если число выпусков больше 2
    const extractedTitles = [];
    if (casts.length > 2) {
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 1));
      extractedTitles.push(..._.slice(casts[1].keywords, 0, 1));
      extractedTitles.push(..._.slice(casts[2].keywords, 0, 1));
    } else if (casts.length > 1) {
      // если число выпусков больше 1
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 2));
      extractedTitles.push(..._.slice(casts[1].keywords, 0, 1));
    } else if (casts.length > 0) {
      // если число выпусков равно 1
      extractedTitles.push(..._.slice(casts[0].keywords, 0, 3));
    }
    setTitles(extractedTitles);
  };

  useEffect(() => {
    dispatch(
      getCasts(
        {},
        {
          onSuccess(body) {
            setCasts(body);
            extractTitles(body);
            setLoading(false);
          },
          onFailure() {
            setLoading(false);
          },
          onRequest() {
            setLoading(true);
          },
        }
      )
    );
  }, []);

  const selectCastId = (castId) => {
    navigate(castId ? `/?castId=${castId}&favorites=1` : "?");
  };
  const { env } = useSelector((state) => state.frontend);
  const { favoritesDescription } = env.settings;

  return (
    <div>
      {isLoading ? null : (
        <>
          {casts.length ? (
            <div className="pb-3 text-center">
              <div className="large-header  pt-2 pb-2">{translate("favorites")}</div>

              {_.trim(favoritesDescription) ? (
                <div className="text-center standart-header pb-4">
                  {nl2br(favoritesDescription)}
                  {/* {translate("favorites_new_casts", { value: casts.length })} */}
                  {/* {_.concat(titles, [""]).join(". ")} */}
                </div>
              ) : (
                <div className="pb-2" />
              )}

              {swiper ? (
                <div>
                  <SwiperBlock
                    id="favorites-casts"
                    slidesPerView={size.width > 750 ? 3 : 2}
                    slides={_.map(casts, (cast, index) => {
                      return (
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={() => selectCastId(cast._id)}
                        >
                          <CastInfoSmall
                            cast={cast}
                            key={index}
                            defaultImageId={cast.channel?.fileId}
                          />
                        </div>
                      );
                    })}
                  />
                </div>
              ) : (
                <div className="row px-2">
                  {_.map(casts, (cast, index) => {
                    return (
                      <div className={`col-6 col-md-4 px-2`} key={index}>
                        <div
                          key={index}
                          className="pb-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => selectCastId(cast._id)}
                        >
                          <CastInfoSmall
                            cast={cast}
                            key={index}
                            showFavorite={true}
                            defaultImageId={cast.channel?.fileId}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export const FavoritesBlock = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const [casts, setCasts] = useState([]);
  const [title, setTitle] = useState("");
  const size = useWindowSize();
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const maxTitlesLimit = 3;

  useEffect(() => {
    dispatch(
      getCasts(
        {},
        {
          onSuccess(body) {
            setCasts(body);
            setLoading(false);
          },
          onFailure() {
            setLoading(false);
          },
          onRequest() {
            setLoading(true);
          },
        }
      )
    );
  }, []);

  useEffect(() => {
    if (casts.length) {
      const texts = [];
      casts.forEach((cast, index) => {
        // добавим заголовки пока их количество допустимо
        if (index < maxTitlesLimit) {
          texts.push(castTitle(cast));
        }
      });
      if (casts.length > maxTitlesLimit) {
        const titlesRest = casts.length - maxTitlesLimit;
        const lastText = pluralRu(titlesRest, "новый выпуск", "новых выпуска", "новых выпусков");
        texts.push(`и ещё ${titlesRest} ${lastText}`);
      }
      setTitle(`${texts.join(", ")}.`);
    }
  }, [casts]);

  return casts.length ? (
    <div
      className="bg-grey pt-4 pb-3 mt-3 mb-4 rounded-xl text-center"
      onClick={() => navigate("/favorites")}
      style={{ cursor: "pointer" }}
    >
      <div className="large-header pb-2">{translate("favorites")}</div>
      {/* <div className="standart-header content-html pb-3">{title}</div> */}
      <div className="">
        <SwiperBlock
          withEffects={true}
          id="favorites-casts"
          // slidesPerView={size.width > 750 ? 3 : 2}
          slidesPerView={Math.min(maxTitlesLimit, casts.length)}
          slides={_.slice(casts, 0, maxTitlesLimit).map((cast, index) => {
            const defaultImageId = cast.channel?.fileId;

            return (
              <div className="position-relative" key={index}>
                <div className="text-center position-relative  rounded rounded-xl border border-1">
                  <div
                    className="block-squared -d-md-none"
                    // style={backgroundCover(fileUrl(cast.fileId || defaultImageId, "image.jpg"))}
                  ></div>
                  <div className="d-flex w-100 h-100 position-absolute" style={{ left: 0, top: 0 }}>
                    <SmartImage
                      src={fileUrl(cast.fileId || defaultImageId, "image.jpg")}
                      style={{ objectFit: "cover", overflow: "hidden" }}
                      className="rounded rounded-xl w-100"
                    />
                  </div>
                </div>

                {!cast.channelId ? (
                  <div className="position-absolute w-100 cover-text-block-small d-flex rounded rounded-xl">
                    <div className="mt-auto w-100">
                      <CastChannelsLogos {...cast} limit={5} />
                      <div className="pb-2" />
                    </div>
                  </div>
                ) : null}
              </div>
            );
          })}
        />
        <div className="pt-2" />
        <MoreCasts alias={"favorites"} />
      </div>

      {/* <Link to={"/favorites"} className="btn btn-solid mt-4">
        <i className="far fa-list mr-2" />
        {translate("open")}
      </Link> */}
      {/* <div className="pt-4" /> */}
    </div>
  ) : null;
};

export default connect(null, {})(FavoritesCasts);
